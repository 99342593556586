


import { Component, Vue } from 'vue-property-decorator';
import { TContact } from '@/_types/contact.type';
import { TMeeting } from '@/_types/meeting/meeting.type';
import { MeetingStatus } from '@/_modules/meeting-rooms/types/meeting-status.enum';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import MeetingsHelper from '@/_helpers/meetings.helper';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { Action, Getter } from 'vuex-class';
import { TGetManagedMeetingsParams } from '@/_api/meetings/meetings.api';
import statisticsApi from '@/_modules/statistics/api/statistics.api';
import FileHelper from '@/_helpers/file.helper';

const MEETINGS_PER_PAGE = 40;

@Component
export default class CabinetMeetings extends Vue {

  @Getter('promoPageStore/contact') public readonly myself: TContact;
  @Getter('meetingsStore/managedMeetingsPage') public readonly managedMeetingsPage: TApiListResponse<TMeeting>;
  @Action('meetingsStore/requestManagedMeetingsPage') public sendManagedMeetingsPageRequest: (params: TGetManagedMeetingsParams) => Promise<void>;
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number }) => void;

  // DATA
  public currentPageNumber: number = 0;
  public isMeetingsBeingRequested: boolean = false;
  public showTotalsSection: boolean = true; // TODO: remove me when totals are implemented

  // COMPUTED

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get currentPageToDisplay(): number {
    return this.currentPageNumber + 1;
  }

  public get meetingsPage(): TMeeting[] {
    if (!this.managedMeetingsPage || !this.managedMeetingsPage.List) {
      return [];
    }
    return this.managedMeetingsPage.List || [];
  }

  public get totalPages(): number {
    if (!this.managedMeetingsPage || !this.managedMeetingsPage.List) {
      return 0;
    }
    const meetingsTotal = this.managedMeetingsPage.Total || 0;
    return Math.ceil(meetingsTotal / MEETINGS_PER_PAGE);
  }

  public get totalMeetingsCount(): number {
    if (!this.managedMeetingsPage || typeof this.managedMeetingsPage.Total !== 'number') {
      return 0;
    }
    return this.managedMeetingsPage.Total;
  }

  public get isPagerNeeded(): boolean {
    return this.totalPages > 0;
  }

  public get skeletonMeetings(): TMeeting[] {
    const result: TMeeting[] = [];
    const dummyMeeting: TMeeting = {
      id: -1,
      date_start: new Date(),
      date_end: new Date(),
      duration_min: 0,
      contact: this.myself,
      status: MeetingStatus.Unconfirmed,
      is_mine: true,
      is_creator: false,
      creator_contact: this.myself,
      user_contact: this.myself,
    };
    for (let i = 0; i < MEETINGS_PER_PAGE; i++) {
      result.push(dummyMeeting);
    }
    return result;
  }

  // HOOKS

  public mounted(): void {
    this.requestManagedMeetingsPage();
  }

  // METHODS

  private async requestManagedMeetingsPage(): Promise<void> {
    this.isMeetingsBeingRequested = true;
    await this.sendManagedMeetingsPageRequest({
      eventId: this.eventId,
      limit: MEETINGS_PER_PAGE,
      offset: this.currentPageNumber * MEETINGS_PER_PAGE,
    });
    this.isMeetingsBeingRequested = false;
  }

  private onPreviousPage(): void {
    this.currentPageNumber--;
    this.requestManagedMeetingsPage();
  }

  private onNextPage(): void {
    this.currentPageNumber++;
    this.requestManagedMeetingsPage();
  }

  private showContactCard(contact: TContact): void {
    this.openContactCard({contactId: contact.id});
  }

  private onCopyMeetingLinkClick(meeting: TMeeting): void {
    const copyContentField: HTMLFormElement = (this.$refs.copyMeetingLinkField as HTMLFormElement);

    if (!copyContentField) {
      return;
    }

    copyContentField.value = MeetingsHelper.getMeetingInviteUrl({
      type: MeetingRoomType.MEETING,
      eventId: this.eventId,
      meetingId: meeting.id,
      meetingDate: this.$moment(meeting.date_start).unix(),
    });

    copyContentField.select();
    document.execCommand('copy');
    copyContentField.value = '';

    this.displayCopyMeetingLinkVisualFeedback(meeting.id);

  }

  private displayCopyMeetingLinkVisualFeedback(meetingId: number): void {
    const refName: string = 'meetingLinkCopier_' + meetingId.toFixed(0);
    if (!this.$refs[refName]) {
      return;
    }
    const clickedCopyTrigger: Element = (this.$refs[refName] as Element[])[0];
    clickedCopyTrigger.classList.add('copy-trigger_success');
    window.setTimeout(() => {
      try {
        clickedCopyTrigger.classList.remove('copy-trigger_success');
      } catch {
        /* ignore */
      }
    }, 3500);
  }

  private getMeetingDateFormatted(meeting: TMeeting): string {
    return this.$moment(meeting.date_start).format('DD.MM.YYYY');
  }

  private getMeetingTimeSlotFormatted(meeting: TMeeting): string {
    const formatString = 'HH:mm';
    const startTimeFormatted: string = this.$moment(meeting.date_start).format(formatString);
    const endTimeFormatted: string = this.$moment(meeting.date_end).format(formatString);
    return [startTimeFormatted, endTimeFormatted].join('-');
  }

  private async exportManagedMeetings(exportFileFormat: string): Promise<void> {
    const file = await statisticsApi.exportManagedMeetings({
      eventId: this.eventId,
      format: exportFileFormat
    });
    this.downloadFile(file, 'EventsWallet_all_meetings_event_' + this.eventId.toFixed(0) + '.' + exportFileFormat);
  }

  public downloadFile(file: File, filename: string): void {
    FileHelper.downloadFile(file, filename);
  }

  public isExhibitor(contact: TContact): boolean {
    return !!contact.promopage_external_id;
  }
}
